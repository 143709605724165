define("ember-bootstrap/components/bs-modal/header/close", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button type="button" aria-label="Close" class="close" ...attributes {{on "click" (bs-default @onClick (bs-noop))}}>
    <span aria-hidden="true">&times;</span>
  </button>
  */
  {
    "id": "5/NvVDaE",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[24,\"aria-label\",\"Close\"],[24,0,\"close\"],[17,1],[4,[38,0],[\"click\",[28,[37,1],[[30,2],[28,[37,2],null,null]],null]],null],[12],[1,\"\\n  \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"×\"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@onClick\"],false,[\"on\",\"bs-default\",\"bs-noop\"]]",
    "moduleName": "ember-bootstrap/components/bs-modal/header/close.hbs",
    "isStrictMode": false
  });
  /**
  
   @class ModalHeaderClose
   @namespace Components
   @extends Glimmer.Component
   @private
   */

  /**
   * @event onClick
   * @public
   */
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});