define("ember-popper/templates/components/ember-popper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QcvF4TX2",
    "block": "[[[41,[30,0,[\"renderInPlace\"]],[[[1,\"  \"],[11,0],[16,1,[30,1]],[16,0,[30,2]],[16,\"hidden\",[30,0,[\"hidden\"]]],[16,\"role\",[30,3]],[17,4],[4,[38,1],[[28,[37,2],[[30,0],\"didInsertPopperElement\"],null]],null],[4,[38,3],[[28,[37,2],[[30,0],\"willDestroyPopperElement\"],null]],null],[4,[38,4],[[28,[37,2],[[30,0],\"didUpdatePopperSettings\"],null],[30,0,[\"eventsEnabled\"]],[30,0,[\"modifiers\"]],[30,0,[\"onCreate\"]],[30,0,[\"onUpdate\"]],[30,0,[\"placement\"]],[30,0,[\"popperTarget\"]],[30,0,[\"renderInPlace\"]]],null],[12],[1,\"\\n    \"],[18,5,[[28,[37,6],null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[28,[37,2],[[30,0],\"disableEventListeners\"],null],[28,[37,2],[[30,0],\"enableEventListeners\"],null],[28,[37,2],[[30,0],\"scheduleUpdate\"],null],[28,[37,2],[[30,0],\"update\"],null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[40,[[[1,\"    \"],[11,0],[16,1,[30,1]],[16,0,[30,2]],[16,\"hidden\",[30,0,[\"hidden\"]]],[16,\"role\",[30,3]],[17,4],[4,[38,1],[[28,[37,2],[[30,0],\"didInsertPopperElement\"],null]],null],[4,[38,3],[[28,[37,2],[[30,0],\"willDestroyPopperElement\"],null]],null],[4,[38,4],[[28,[37,2],[[30,0],\"didUpdatePopperSettings\"],null],[30,0,[\"eventsEnabled\"]],[30,0,[\"modifiers\"]],[30,0,[\"onCreate\"]],[30,0,[\"onUpdate\"]],[30,0,[\"placement\"]],[30,0,[\"popperTarget\"]],[30,0,[\"renderInPlace\"]]],null],[12],[1,\"\\n      \"],[18,5,[[28,[37,6],null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[28,[37,2],[[30,0],\"disableEventListeners\"],null],[28,[37,2],[[30,0],\"enableEventListeners\"],null],[28,[37,2],[[30,0],\"scheduleUpdate\"],null],[28,[37,2],[[30,0],\"update\"],null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"_popperContainer\"]],null]],[]]]],[\"@id\",\"@class\",\"@ariaRole\",\"&attrs\",\"&default\"],false,[\"if\",\"did-insert\",\"action\",\"will-destroy\",\"did-update\",\"yield\",\"hash\",\"in-element\"]]",
    "moduleName": "ember-popper/templates/components/ember-popper.hbs",
    "isStrictMode": false
  });
});