define("ember-intl/services/intl", ["exports", "@ember/application", "@ember/object", "@ember/object/computed", "@ember/object/evented", "@ember/debug", "@ember/array", "@ember/service", "@ember/runloop", "ember-intl/-private/formatters", "ember-intl/-private/utils/is-array-equal", "ember-intl/-private/utils/normalize-locale", "ember-intl/-private/utils/get-dom", "ember-intl/-private/utils/hydrate", "ember-intl/-private/store/container"], function (_exports, _application, _object, _computed, _evented, _debug, _array, _service, _runloop, _formatters, _isArrayEqual, _normalizeLocale, _getDom, _hydrate, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); } /**
                                                                                                                                                                                                                                                                                                                                  * Copyright 2015, Yahoo! Inc.
                                                                                                                                                                                                                                                                                                                                  * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
                                                                                                                                                                                                                                                                                                                                  */
  var _default = _exports.default = _service.default.extend(_evented.default, {
    /** @public **/
    formats: null,
    /**
     * Returns an array of registered locale names
     *
     * @property locales
     * @public
     */
    locales: (0, _computed.readOnly)('_translationContainer.locales'),
    /** @public **/
    locale: (0, _object.computed)('_locale', {
      set: function set(_, localeName) {
        var _this = this;
        var proposed = (0, _array.makeArray)(localeName).map(_normalizeLocale.default);
        if (!(0, _isArrayEqual.default)(proposed, this._locale)) {
          (0, _object.set)(this, '_locale', proposed);
          (0, _runloop.cancel)(this._timer);
          this._timer = (0, _runloop.next)(function () {
            _this.trigger('localeChanged');
            _this._updateDocumentLanguage(_this._locale);
          });
        }
        return this._locale;
      },
      get: function get() {
        return (0, _object.get)(this, '_locale');
      }
    }),
    /**
     * Returns the first locale of the currently active locales
     *
     * @property primaryLocale
     * @public
     */
    primaryLocale: (0, _computed.readOnly)('locale.0'),
    /** @public **/
    formatRelative: createFormatterProxy('relative'),
    /** @public **/
    formatMessage: createFormatterProxy('message'),
    /** @public **/
    formatNumber: createFormatterProxy('number'),
    /** @public **/
    formatTime: createFormatterProxy('time'),
    /** @public **/
    formatDate: createFormatterProxy('date'),
    /** @private **/
    _translationContainer: null,
    /** @private **/
    _locale: null,
    /** @private **/
    _timer: null,
    /** @private **/
    _formatters: null,
    /** @public **/init: function init() {
      this._super.apply(this, arguments);
      var initialLocale = (0, _object.get)(this, 'locale') || ['en-us'];
      this.setLocale(initialLocale);
      this._owner = (0, _application.getOwner)(this);
      // Below issue can be ignored, as this is during the `init()` constructor.
      // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
      this._translationContainer = _container.default.create();
      this._formatters = this._createFormatters();
      if (!this.formats) {
        this.formats = this._owner.resolveRegistration('formats:main') || {};
      }
      (0, _hydrate.default)(this);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      (0, _runloop.cancel)(this._timer);
    },
    /** @private **/onError: function onError(_ref) {
      var error = _ref.error;
      throw error;
    },
    /** @public **/lookup: function lookup(key, localeName) {
      var localeNames = this._localeWithDefault(localeName);
      var translation;
      for (var i = 0; i < localeNames.length; i++) {
        translation = this._translationContainer.lookup(localeNames[i], key);
        if (translation !== undefined) {
          break;
        }
      }
      return translation;
    },
    /** @private **/lookupAst: function lookupAst(key, localeName) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var localeNames = this._localeWithDefault(localeName);
      var translation;
      for (var i = 0; i < localeNames.length; i++) {
        translation = this._translationContainer.lookupAst(localeNames[i], key);
        if (translation !== undefined) {
          break;
        }
      }
      if (translation === undefined && options.resilient !== true) {
        var missingMessage = this._owner.resolveRegistration('util:intl/missing-message');
        return missingMessage.call(this, key, localeNames, options);
      }
      return translation;
    },
    validateKeys: function validateKeys(keys) {
      return keys.forEach(function (key) {
        (false && !(typeof key === 'string') && (0, _debug.assert)("[ember-intl] expected translation key \"".concat(key, "\" to be of type String but received: \"").concat(_typeof(key), "\""), typeof key === 'string'));
      });
    },
    /** @public **/t: function t(key) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var keys = [key];
      if (options.default) {
        if (Array.isArray(options.default)) {
          keys = [].concat(_toConsumableArray(keys), _toConsumableArray(options.default));
        } else if (typeof options.default === 'string') {
          keys = [].concat(_toConsumableArray(keys), [options.default]);
        }
      }
      this.validateKeys(keys);
      for (var index = 0; index < keys.length; index++) {
        var _key = keys[index];
        var ast = this.lookupAst(_key, options.locale, _objectSpread(_objectSpread({}, options), {}, {
          // Note: last iteration will throw with the last key that was missing
          // in the future maybe the thrown error should include all the keys to help debugging
          resilient: keys.length - 1 !== index
        }));
        if (ast) {
          return this.formatMessage(ast, options);
        }
      }
    },
    /** @public **/exists: function exists(key, localeName) {
      var _this2 = this;
      var localeNames = this._localeWithDefault(localeName);
      (false && !(Array.isArray(localeNames) && localeNames.length) && (0, _debug.assert)("[ember-intl] locale is unset, cannot lookup '".concat(key, "'"), Array.isArray(localeNames) && localeNames.length));
      return localeNames.some(function (localeName) {
        return _this2._translationContainer.has(localeName, key);
      });
    },
    /** @public */setLocale: function setLocale(locale) {
      (0, _object.set)(this, 'locale', locale);
    },
    /** @public **/addTranslations: function addTranslations(localeName, payload) {
      this._translationContainer.push((0, _normalizeLocale.default)(localeName), payload);
    },
    /** @public **/translationsFor: function translationsFor(localeName) {
      return this._translationContainer.findTranslationModel((0, _normalizeLocale.default)(localeName), false);
    },
    /** @private **/_localeWithDefault: function _localeWithDefault(localeName) {
      if (!localeName) {
        return (0, _object.get)(this, '_locale') || [];
      }
      if (typeof localeName === 'string') {
        return (0, _array.makeArray)(localeName).map(_normalizeLocale.default);
      }
      if (Array.isArray(localeName)) {
        return localeName.map(_normalizeLocale.default);
      }
    },
    /** @private **/_updateDocumentLanguage: function _updateDocumentLanguage(locales) {
      var dom = (0, _getDom.default)(this);
      if (dom) {
        var _locales = _slicedToArray(locales, 1),
          primaryLocale = _locales[0];
        var html = dom.documentElement;
        html.setAttribute('lang', primaryLocale);
      }
    },
    /** @private */_createFormatters: function _createFormatters() {
      var _this3 = this;
      var formatterConfig = {
        onError: this.onError.bind(this),
        readFormatConfig: function readFormatConfig() {
          return _this3.formats;
        }
      };
      return {
        message: new _formatters.FormatMessage(formatterConfig),
        relative: new _formatters.FormatRelative(formatterConfig),
        number: new _formatters.FormatNumber(formatterConfig),
        time: new _formatters.FormatTime(formatterConfig),
        date: new _formatters.FormatDate(formatterConfig)
      };
    }
  });
  function createFormatterProxy(name) {
    return function serviceFormatterProxy(value, formatOptions) {
      var locale;
      if (formatOptions && formatOptions.locale) {
        locale = this._localeWithDefault(formatOptions.locale);
      } else {
        locale = (0, _object.get)(this, 'locale');
      }
      return this._formatters[name].format(locale, value, formatOptions);
    };
  }
});