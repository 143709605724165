define("active-model-adapter/initializers/active-model-adapter", ["exports", "active-model-adapter", "active-model-adapter/active-model-serializer"], function (_exports, _activeModelAdapter, _activeModelSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register('adapter:-active-model', _activeModelAdapter.default);
    application.register('serializer:-active-model', _activeModelSerializer.default);
  }
  var _default = _exports.default = {
    initialize: initialize
  };
});