define('@fortawesome/pro-light-svg-icons', ['exports'], function (exports) { 'use strict';

  /*!
   * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
   * License - https://fontawesome.com/license (Commercial License)
   */
  var faChevronLeft = {
    prefix: 'fal',
    iconName: 'chevron-left',
    icon: [256, 512, [], "f053", "M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"]
  };

  exports.faChevronLeft = faChevronLeft;

  Object.defineProperty(exports, '__esModule', { value: true });

});
