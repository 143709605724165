define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "answer-message": "__a62de",
    "app-footer": "__10012",
    "app-logo": "__70295",
    "app-main": "__1770d",
    "app-svg-icon": "__0a613",
    "app-top-line": "__1acc2",
    "reset-modal": "__bcbb4",
    "reset-timer": "__edd10",
    "survey-extremes": "__29c49",
    "survey-question": "__d0005",
    "survey-scale": "__b2426",
    "not-found": "__62a4b",
    "survey/answer": "__2b2d9",
    "survey/setup": "__5795c"
  };
});